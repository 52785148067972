import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TrafficView from '../views/TrafficView.vue'
import DivorceView from '../views/DivorceView.vue'
import ArticleView from '../views/ArticleView.vue'
import NewsIndexView from '../views/NewsIndexView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {title: '楊朝鈞律師-事務所簡介'},
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta: {title: '楊朝鈞律師-律師介紹'},
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: {title: '楊朝鈞律師-服務項目'},
    component: () => import(/* webpackChunkName: "service" */ '../views/ServiceView.vue')
  },
  {
    path: '/advice',
    name: 'advice',
    meta: {title: '楊朝鈞律師-法律線上諮詢'},
    children: [
      {
        path: 'traffic',
        name: 'traffic',
        component: TrafficView,
        meta: {title: '楊朝鈞律師-車禍責任分析'},
      },
      {
        path: 'divorce',
        name: 'divorce',
        component: DivorceView,
        meta: {title: '楊朝鈞律師-離婚法律分析'},
      }
    ],
    component: () => import(/* webpackChunkName: "advice" */ '../views/AdviceView.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {title: '楊朝鈞律師-專欄與新知'},
    children: [
      {
        path: 'index',
        name: 'index',
        component: NewsIndexView,
        meta: {title: '楊朝鈞律師-專欄首頁'},
      },
      {
        path: 'article/:id',
        name: 'article',
        component: ArticleView,
        meta: {title: '楊朝鈞律師-專欄一'},
      }
    ],
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue')
  }
]

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes
})

export default router
