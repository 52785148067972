import { createApp } from 'vue'
// import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'

//- meta title
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
      document.title = to.meta.title
  }
  next();
});

// const store = createStore({
//     state () {
//       return {
//         count: 0,
//         active: true
//       }
//     },
//     mutations: {
//       increment (state) {
//         state.count++
//       }
//     }
// })



createApp(App).use(router).mount('#app')
// createApp(App).use(store)

// store.commit('increment')
// console.log(store.state.count,'來自main.js')
// console.log(store.state.active,'來自main.js')



